import React, {useEffect, useState} from "react";
import '../../report.css';
import youtubeImage from '../../images/youtube_new.png';
import sectionTop from '../../images/report_section1.jpg';
import sectionLeft from '../../images/report_section2-left.jpg';
import sectionRight from '../../images/report_section3-right.jpg';
import axios from "axios";
import {baseURL} from "../../global/global";
import {useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import redditImage from "../../images/reddit_new.png";
import {setAlert} from "../../store/actions/alert";
import Navbar from "./Navbar";
import BannerLinebar from "./BannerLinebar";

const ReportGenerate = (props) => {

    const auth = useSelector(state => state.auth);
    const streamData = useSelector(state => state.scheduler.schedule);

    const [agencyData, setAgencyData] = useState([]);
    const [agencyStatus, setAgencyStatus] = useState(false);

    const [accounts, setAccounts] = useState([]);
    const [client, setClient] = useState([]);
    const [scheduler, setScheduler] = useState([]);

    let filterClient = streamData.filter(schedule => schedule.user_client_id === props.match.params.id);
    const [scheduleClient, setScheduleClient] = useState(filterClient);

    useEffect(() =>{
        let params = props.match.params;
        console.log(params);

        axios({
            method: 'POST',
            url: `${baseURL}client-report`,
            data: params,
            headers: {'Content-Type': 'application/json','Authorization' : auth.token}
        }).then(res=>{
            console.log(res);
            if(res.data.status === true){
                setAccounts(res.data.data.social_accounts);
                setClient(res.data.data.client);
                setScheduler(res.data.data.scheduler);
            }
        }).catch(err=>{

        })

    },[])


    const fetchAgency = async () => {
        await axios({
            method: "POST",
            url: `${baseURL}fetch-agency`,
            data: {},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
               setAgencyData(res.data.data);
                setAgencyStatus(true);
            }
        }).catch(err=>{
        })
    }


    useEffect(()=>{
        setScheduleClient(filterClient);
        fetchAgency();
    },[])

    useEffect(()=>{

    },[accounts,client,scheduler])

    const generatePdf = (e) =>{
        let printContents = document.getElementById('generate_pdf').innerHTML;
        let originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();
        document.body.innerHTML = originalContents;

    }

    return (
        <>
            <Helmet>
                <title>AgencyReel | Generate Report </title>
            </Helmet>
            <Navbar/>
            <BannerLinebar/>
            <section className="report-sec px-5 py-5">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="print-btn">
                                <a style={{cursor: "pointer"}} onClick={(e)=>generatePdf(e)}  target="_blank">
                                    <span className="mr-2"><i className="fas fa-print"></i></span>PRINT
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="report-img">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 d-flex justify-content-center ">
                            <div className="logo-img mb-5" style={{borderRadius:"50%" , overflow:"hidden"}} >
                                {
                                    agencyStatus ? <img src={agencyData.logo} className="img-fluid" width="250px" alt=""/> : <img src={auth.user.image} className="img-fluid" width="250px" alt=""/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

           <div id="generate_pdf">
               <section className="report-data">
                   <div className="container">
                       <div className="row">
                           <div className="col-md-12">
                               <div className="report-content">
                                   <h1><span className="d-block">REPORT</span> {props.match.params.month}-{props.match.params.year}</h1>
                               </div>
                           </div>
                       </div>
                   </div>
               </section>

               <section>
                   <div className="container-fluid">
                       <div className="row">
                           <div className="col-md-12 p-0">
                               <div className="border-img">
                                   <img src={sectionTop} alt="" style={{height:"150px"}}  />
                               </div>
                           </div>
                       </div>
                   </div>
               </section>

               <section className="avatar-sec">
                   <div className="container-fluid">
                       <div className="row">
                           <div className="col-md-1 p-0">
                               <img src={sectionLeft} alt="" className="img-fluid"  style={{height:"900px"}}  />
                           </div>
                           <div className="col-md-11">
                               <div className="avter-content">

                                   <div className="row mt-5">
                                       <div className="col-md-12">
                                           <h4>
                                               {
                                                   agencyStatus ? agencyData.name : auth.user.name
                                               }
                                           </h4>
                                       </div>
                                   </div>

                                   <div className="row mt-5">
                                       <div className="col-md-12 d-flex justify-content-center">
                                           <div
                                               className="avter-img d-flex justify-content-center align-items-center flex-column"
                                                style={{width: "250px"}}>
                                               <img src={client.image} alt="" className="img-fluid" />
                                               <h2 className="mt-4 text-center">{client.name}</h2>
                                           </div>
                                       </div>
                                   </div>

                               </div>
                           </div>
                       </div>


                   </div>
               </section>


               <section className="social-sec">
                   <div className="container-fluid">
                       <div className="row mt-5">
                           <div className="col-md-11">
                               <div className="social-head py-5">
                                   <h2 className="text-center">SOCIAL NETWORK MANAGED</h2>
                               </div>

                               <div className="social-icon">
                                   <div className="row">
                                       {
                                           accounts.length > 0 ?
                                               accounts.map((item,index)=>{
                                                   return(
                                                       <div className="col-md-6 mt-4 d-flex flex-column align-items-center justify-content-center mb-5" key={index}>
                                                           <div id={item.type} className={`facebook-icon ${item.type}-social-bgcolor`}>
                                                               <span><i className={`fab fa-5x fa-${item.type}`}></i></span>
                                                           </div>
                                                           <h5 className="mt-4" >{item.username}</h5>
                                                       </div>
                                                   )
                                               })
                                           : ''
                                       }
                                   </div>
                               </div>
                           </div>
                           <div className="col-md-1 pr-0 d-flex justify-content-end">
                               <img src={sectionRight} alt="" className="img-fluid" style={{height:"1510px"}}  />
                           </div>
                       </div>
                   </div>
               </section>


               <section>
                   <div className="container-fluid">
                       <div className="row">
                           <div className="col-md-1 pl-0">
                               <img src={sectionLeft} alt="" className="img-fluid" style={{height:"1000px"}} />
                           </div>
                           <div className="col-md-11">
                               <table className="table table-bordered table-responsive-lg">
                                   <thead>
                                   <tr>
                                       <th scope="col">Content Stream Title</th>
                                       <th scope="col">Schedule Status</th>
                                       <th scope="col">Post URL</th>
                                       <th scope="col">Schedule Date and Time</th>
                                       <th scope="col">TIME ZONE</th>
                                       <th scope="col">SCHEDULER TYPE</th>
                                   </tr>
                                   </thead>

                                   <tbody>
                                   {
                                       scheduler.length > 0 ?
                                           scheduler.map((item,index)=>{
                                              return(
                                                  JSON.parse(item.streams).length > 0 ?
                                                      JSON.parse(item.streams).map((stream, i)=>{
                                                          console.log(stream)
                                                          return(
                                                              <tr>
                                                                  <td>{stream.title !== '' ? stream.title : 'N/A'}</td>
                                                                  <td>
                                                                      {(() => {
                                                                          if (stream.done === 1) {
                                                                              return (
                                                                                  <span style={{color: "green"}}>Posted</span>
                                                                              )
                                                                          }
                                                                      })()}
                                                                      {(() => {
                                                                          if (stream.done === '0') {
                                                                              return (
                                                                                  <span style={{color: "orange"}}>Scheduled</span>
                                                                              )
                                                                          }
                                                                      })()}
                                                                      {(() => {
                                                                          if (stream.done === '2') {
                                                                              return (
                                                                                  <span style={{color: "red"}}>Failed</span>
                                                                              )
                                                                          }
                                                                      })()}
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          stream.social_networks.length > 0 ?
                                                                              stream.social_networks.map((social, ind)=>{
                                                                                  return(
                                                                                      <>
                                                                                          {
                                                                                              social.post_url !== "" ? <a target="_blank" href={social.post_url}><i className={`fab fa-${social.type}`}></i></a> : ""
                                                                                          }
                                                                                      </>
                                                                                  )
                                                                              })
                                                                           : ''
                                                                      }
                                                                  </td>
                                                                  <td>
                                                                      {stream.scheduled}
                                                                  </td>
                                                                  <td>
                                                                      {item.timezone}
                                                                  </td>
                                                                  <td className="uc-first">
                                                                      {item.type}
                                                                  </td>
                                                              </tr>
                                                          )
                                                      })
                                                  : ''
                                              )
                                           })
                                           : <tr align="center"><td colSpan="6">No Data Found</td></tr>
                                   }
                                   </tbody>
                               </table>

                               <h5 className="d-flex justify-content-between" style={{opacity: ".5"}}>
                                   <span>{agencyStatus ? agencyData.email : auth.user.email}</span>
                                   <span>{agencyStatus ? agencyData.site : auth.user.name}</span>
                               </h5>
                           </div>

                       </div>
                   </div>

               </section>
           </div>
        </>
    );
}

export default ReportGenerate;